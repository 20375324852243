<!--
    Created by 王丽莎 on 2024/06/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称:数据统计-数据分析
-->
<style lang="less">
#data-analysis {
  min-width: 700px;
  .card-outer {
    margin: 0 -15px;
    .total-data-box {
      display: flex;
      overflow: auto;
      //flex-wrap: wrap;
      //overflow: hidden;
      border-radius: @borderRadiusMin;
      border: 1px solid @lineColorNormal;
      border-right: 0;
      .total-data-item {
        flex: 1;
        min-width: 100px;
        border-right: 1px solid @lineColorNormal;
        &:nth-of-type(2n) {
          .total-data-label {
            //background-color: transparent;
          }
        }
        .total-data-label {
          padding: 8px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          white-space: pre-line;
          color: @textColorLight;
          background-color: @backColorNormal;
        }
        .total-data-value {
          .display-flex();
          line-height: 55px;
          font-weight: 600;
          font-size: 13px;
        }
        .sa-price-total-data-value {
          height: 55px;
          padding: 7px 0;
          box-sizing: border-box;
          .__g-table-key-value {
            line-height: 14px;
          }
          .__g-table-key-value + .__g-table-key-value {
            margin-top: 0;
          }
        }
      }
    }
  }
  .color-text-strong { color: @adornColor; font-weight: 600 }
  .color-text-finish { color: @finishColor; }
  .color-text-primary { color: @textColorNormal; }
  td.ivu-table-expanded-cell {
    padding: 20px;
  }
}
</style>

<template>
  <div id="data-analysis">
    <!-- 查询条件 -->
    <div v-permission="'system:statis:device:list'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div
      v-permission="'system:statis:device:list'"
      class="card-outer"
    >
      <yg-card
        title="统计概览"
      >
        <div slot="content">
          <div class="total-data-box">
            <div
              v-for="(item, i) in totalItems"
              :key="i"
              class="total-data-item"
              :style="'min-width: ' + item.itemWidth + 'px'"
            >
              <div class="total-data-label">
                {{ item.label }}
              </div>
              <div
                v-if="item.isKeyValue"
                class="sa-price-total-data-value"
              >
                <yg-attribute-key-value
                  v-for="(re, ind) in item.keyValueData"
                  :key="ind"
                  :label="re.label"
                  :label-width="80"
                  :value="pageData[re.value] + ''"
                  :can-price="item.priceText || re.isPrice"
                  :can-text-unit="re.canTextUnit"
                />
              </div>
              <yg-attribute-span
                v-else
                :can-text-unit="item.canTextUnit"
                :can-price="item.priceText"
                :text="pageData[item.key] + ''"
                class="total-data-value"
                :class="'color-text-' + item.textColor"
              />
            </div>
          </div>
        </div>
      </yg-card>
    </div>
    <div
      v-permission="'system:statis:device:list'"
      class="card-outer"
      style="margin-top: -5px"
    >
      <yg-card
        title="详细信息"
        style="padding-bottom: 0"
      >
        <div slot="content">
          <div class="margin-primary-bottom">
            <yg-setting-area
              :items="settingItems"
              :total="page.total"
              :export-condition="settingExportCondition"
              @on-click="_clickSetting"
            />
          </div>
          <yg-table
            :columns="tableColumns"
            :data="tableData"
            :page="page"
            :max-height="9999"
            :row-class-name="mixinTableRowClassName"
            @on-row-expand="mixinTableOnRowExpand"
            @on-page-change="onChangePage"
          />
        </div>
      </yg-card>
    </div>
  </div>
</template>

<script>
import { DataAnalysisTotalModel, DataAnalysisListModel } from './model/DataAnalysisDataModel';
import DataStatisticsApiSet from '@/request/api/DataStatisticsApiSet';
import ColumnMixin from './mixin/tableColumn';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import { getLastDate } from '@/libs/utils';
export default {
  components: {},
  mixins: [ColumnMixin, pageTableExpandMixin],
  data () {
    return {
      totalItems: [
        {
          key: '',
          label: '统计\n日期范围',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 180,
          keyValueData: [
            { label: '开始:', value: 'statisDateBegin' },
            { label: '结束:', value: 'statisDateEnd' }
          ]
        },
        { key: 'portUseRate', label: '端口\n使用率', textColor: 'strong', priceText: false, isKeyValue: false, canTextUnit: 'p' },
        { key: 'enabledDeviceNum', label: '充电桩\n总数', textColor: 'strong', priceText: false, isKeyValue: false },
        { key: 'enabledPortNum', label: '启用端口\n总数', textColor: 'strong', priceText: false, isKeyValue: false },
        { key: 'availableOrderNum', label: '有效充电\n订单数', textColor: 'strong', priceText: false, isKeyValue: false },
        { key: 'orderNum', label: '充电订单\n总数', textColor: 'strong', priceText: false, isKeyValue: false },
        // { key: 'orderNumChargeCoin', label: '充电币购买\n订单数', textColor: 'finish', priceText: false, isKeyValue: false },
        // { key: 'orderAmountChargeCoin', label: '充电币购买\n订单总金额', textColor: 'finish', priceText: true, isKeyValue: false },
        { key: 'activeNum', label: '新装\n充电桩总数', textColor: 'finish', priceText: false, isKeyValue: false },
        // {
        //   key: '',
        //   label: '有效充电订单\n总实收金额(渠道)',
        //   textColor: 'primary',
        //   priceText: false,
        //   isKeyValue: true,
        //   itemWidth: 200,
        //   keyValueData: [
        //     { label: '微信:', value: '', isPrice: true },
        //     { label: '充电币:', value: '' }
        //   ]
        // },
        {
          key: '',
          label: '充电订单\n分账金额(微信)',
          textColor: 'primary',
          priceText: true,
          isKeyValue: true,
          itemWidth: 200,
          keyValueData: [
            { label: '服务商:', value: 'orderAllocateAmountSpWechat' },
            { label: '物业公司:', value: 'orderAllocateAmountPcWechat' },
            { label: '代理商:', value: 'orderAllocateAmountAgentWechat' }
          ]
        },
        {
          key: '',
          label: '充电订单\n分账金额(充电币)',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 200,
          keyValueData: [
            { label: '服务商:', value: 'orderAllocateAmountSpCoin' },
            { label: '物业公司:', value: 'orderAllocateAmountPcCoin' },
            { label: '代理商:', value: 'orderAllocateAmountAgentCoin' }
          ]
        },
        {
          key: '',
          label: '充电币购买订单\n分账金额',
          textColor: 'primary',
          priceText: true,
          isKeyValue: true,
          itemWidth: 200,
          keyValueData: [
            { label: '服务商:', value: 'coinOrderAllocateAmountSp' },
            { label: '物业公司:', value: 'coinOrderAllocateAmountPc' },
            { label: '代理商:', value: 'coinOrderAllocateAmountAgent' }
          ]
        },
        {
          key: '',
          label: '平均分账比例\n(充电订单)',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 200,
          keyValueData: [
            { label: '服务商:', value: 'orderAllocateRateSp', canTextUnit: 'p' }
          ]
        },
        {
          key: '',
          label: '平均分账比例\n(充电币购买订单)',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 200,
          keyValueData: [
            { label: '服务商:', value: 'coinOrderAllocateRateSp', canTextUnit: 'p' }
          ]
        },
        { key: 'manualRefundOrderNum', label: '人工退款\n订单数', textColor: 'strong', priceText: false, isKeyValue: false },
        { key: 'manualRefundOrderAmount', label: '人工退款\n金额', textColor: 'strong', priceText: true, isKeyValue: false }
      ],
      searchOption: {
        deviceNoLabel: BaseSearchModel.initData('设备型号名称', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: 'deviceNumber', label: '设备编码' },
          { value: 'deviceName', label: '设备名称' },
          { value: 'deviceModelName', label: '设备型号名称' },
          { value: 'imei', label: 'IMEI号' }
        ]),
        chargingPileType: BaseSearchModel.initData('端口类别', 'chargingPileType', 'YgGlobalSelect').setData('chargingPileType'),
        // deviceSource: BaseSearchModel.initData('数据来源', 'deviceSource', 'YgGlobalSelect').setData('orderDataSourceType'),
        status: BaseSearchModel.initData('启用状态', 'status', 'Select').setData([
          { value: 1, label: '已启用' },
          { value: 0, label: '已停用' }
        ]),
        operationType: BaseSearchModel.initData('运营类型', 'operationType', 'Select').setMultiple(true).setData([
          { label: '自有', value: '0' },
          { label: '共建', value: '1' },
          { label: '外拓', value: '2' }
        ]),
        projectNoLabel: BaseSearchModel.initData('物业SAP项目编码物业', 'projectNoLabel', 'Input').setLabelSelect([
          { value: 'pcSapCode', label: '物业SAP项目编码' },
          { value: 'communityName', label: '小区名称' }
        ]),
        deviceProviderIdList: BaseSearchModel.initData('设备商名称', 'deviceProviderIdList', 'YgGlobalSelect').setMultiple(true).setData('deviceProvider').setDefault([]),
        isOnline: BaseSearchModel.initData('在线状态', 'isOnline', 'Select').setData([
          { label: '离线', value: '0' },
          { label: '在线', value: '1' }
        ]),
        regionCompany: BaseSearchModel.initData('物业区域公司', 'regionCompany', 'YgGlobalSelect').setData('regionalCompanyList').setExclusions(['officeCode', 'region']),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList').setExclusions(['regionCompany', 'region']),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2).setExclusions(['regionCompany', 'officeCode']),
        time: BaseSearchModel.initData('统计日期范围', 'time', 'DatePickerRange').setDefault([getLastDate(2), getLastDate(2)]).setClearable(false)
      },
      settingItems: [
        BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(26).setPermission('system:statis:device:export')
      ],
      settingExportCondition: {},
      page: { current: 1, total: 0 },
      tableData: [],
      pageData: {},
      dialog: {
        detailModel: null
      }
    };
  },
  created () {
    // 配置展开项内容
    this.mixinTableExpandSetColumnOperation(1, [
      {
        title: '设备信息',
        col: '40',
        items: [
          { col: '50', label: '设备名称:', key: 'deviceName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: 'SIM卡ID:', key: 'iccid', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: 'IMEI号:', key: 'imei', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '', key: '', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '安装信息',
        col: '20',
        items: [
          { col: '100', label: '安装点位:', key: 'siteName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '100', label: '安装时间:', key: 'activeTimeStr', canCopy: false, canTooltip: true, canHidePhone: true }
        ]
      },
      {
        title: '省市区',
        col: '40',
        items: [
          { col: '50', label: '省份:', key: 'provinceName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '城市:', key: 'cityName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '区县:', key: 'districtName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '', key: '', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      }
    ]);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRequestParams () {
      const s = this.searchOption;
      const searchStartTime = new Date(s.time.value[0]).getTime();
      const earliestTime = new Date('2024-04-01 00:00:00').getTime();
      // const searchEndTime = new Date(s.time.value[1]).getTime();
      // const deadlineDate = getLastDate(2);
      // const deadlineTime = new Date(deadlineDate + ' 23:59:59').getTime();
      // 最早时间为24年4月1日，最晚时间为昨日
      if (searchStartTime < earliestTime) {
        return {
          status: false,
          msg: '开始日期最早为2024年04月01日',
          params: {}
        };
      }
      // if (searchEndTime > deadlineTime) {
      //   return {
      //     status: false,
      //     msg: '结束日期最晚为' + getLastDate(2),
      //     params: {}
      //   };
      // }
      return {
        status: true,
        msg: '',
        params: {
          chargingPileType: s.chargingPileType.value,
          // deviceSource: s.deviceSource.value,
          status: s.status.value,
          deviceProviderIdList: s.deviceProviderIdList.value,
          isOnline: s.isOnline.value,
          officeCode: s.officeCode.value,
          orgCode: s.regionCompany.value,
          operateTypeList: s.operationType.value || [],
          provinceCode: s.region.value[0] || '',
          cityCode: s.region.value[1] || '',
          districtCode: s.region.value[2] || '',
          statisDateBegin: s.time.value[0] || '',
          statisDateEnd: s.time.value[1] || ''
        }
      };
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {},
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const paramsData = this.getRequestParams();
      if (!paramsData.status) {
        return this.$Message.warning(paramsData.msg);
      }
      this.tableData = [];
      this.page.total = 0;
      const api = DataStatisticsApiSet.getStatisticsList;
      const s = this.searchOption;
      api.params = paramsData.params;
      api.params.pageNo = this.page.current;
      api.params.pageSize = this.$config.pageSize;
      api.params[s.deviceNoLabel.labelSelectValue] = s.deviceNoLabel.value;
      api.params[s.projectNoLabel.labelSelectValue] = s.projectNoLabel.value;
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.pageData = DataAnalysisTotalModel.initModelWithData(resData.statisSummary);
        const list = DataAnalysisListModel.initListWithDataList(resData.list);
        this.tableData = this.mixinTableExpandDataSetExpand(list);
      });
      // const resList = DataAnalysisListModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = DataAnalysisListModel.initListWithDataList(resList);
      // const resData = DataAnalysisTotalModel.createFakeData();
      // this.pageData = DataAnalysisTotalModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
