
export default {
  data () {
    return {
      // 下面数据明细-表头
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('deviceNumber', '设备编码', 120).setSpan({ canCopy: true }),
        BaseTableColumn.initData('communityName', '小区').setSpan({}),
        BaseTableColumn.initData('pcSapCode', '物业SAP项目编码', 140).setSpan({ canCopy: true }),
        BaseTableColumn.initData('operateTypeDesc', '设备运营类型', 100).setSpan({}),
        BaseTableColumn.initData('deviceProviderName', '设备商').setSpan({}),
        BaseTableColumn.initData('portUseRate', '端口使用率').setSpan({ canTextUnit: 'p' }),
        BaseTableColumn.initData('enabledPortNum', '启用端口总数', 100).setSpan({}),
        BaseTableColumn.initData('offlineNum', '累计离线次数', 100).setSpan({}),
        BaseTableColumn.initData('availableOrderNum', '有效充电\n订单数').setSpan({}),
        BaseTableColumn.initData('', '有效充电订单数\n(支付渠道)', 140).setKeyValueList([
          { label: '微信:', labelWidth: 60, key: 'orderNumWechat', attr: { } },
          { label: '充电币:', labelWidth: 60, key: 'orderNumChargeCoin', attr: {} }
        ]),
        BaseTableColumn.initData('', '有效充电订单数\n实收金额(支付渠道)', 140).setKeyValueList([
          { label: '微信:', labelWidth: 60, key: 'orderAmountWechat', attr: { canPrice: true } },
          { label: '充电币:', labelWidth: 60, key: 'orderAmountChargeCoin', attr: {} }
        ]),
        BaseTableColumn.initData('orderNum', '充电\n订单总数').setSpan({}),
        BaseTableColumn.initData('', '充电订单\n分账金额(微信)', 160).setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'orderAllocateAmountSpWechat', attr: { canPrice: true } },
          { label: '物业公司:', labelWidth: 70, key: 'orderAllocateAmountPcWechat', attr: { canPrice: true } },
          { label: '代理商:', labelWidth: 70, key: 'orderAllocateAmountAgentWechat', attr: { canPrice: true } }
        ]),
        BaseTableColumn.initData('', '充电订单\n分账金额(充电币)', 160).setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'orderAllocateAmountSpCoin', attr: {} },
          { label: '物业公司:', labelWidth: 70, key: 'orderAllocateAmountPcCoin', attr: {} },
          { label: '代理商:', labelWidth: 70, key: 'orderAllocateAmountAgentCoin', attr: {} }
        ]),
        BaseTableColumn.initData('', '充电币购买订单\n分账金额', 160).setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'coinOrderAllocateAmountSp', attr: { canPrice: true } },
          { label: '物业公司:', labelWidth: 70, key: 'coinOrderAllocateAmountPc', attr: { canPrice: true } },
          { label: '代理商:', labelWidth: 70, key: 'coinOrderAllocateAmountAgent', attr: { canPrice: true } }
        ]),
        BaseTableColumn.initData('claimCoinOrderNum', '认领充电币购买\n订单数', 140).setSpan({}),
        BaseTableColumn.initData('claimCoinOrderAmount', '认领充电币购买\n订单总金额', 140).setSpan({ canPrice: true }),
        BaseTableColumn.initData('manualRefundOrderNum', '人工退款\n订单数').setSpan({}),
        BaseTableColumn.initData('manualRefundOrderAmount', '人工退款\n金额').setSpan({ canPrice: true })
      ]
    };
  }
};
