/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 数据统计
 */

import BaseApi from '@/request/base/BaseApi';

class DataStatisticsApiSet {
  constructor () {
    this.getStatisticsList = BaseApi.createModel('数据分析-查询列表', '/opmp/statis/device/list').setMethod(1);
  }
}

export default new DataStatisticsApiSet();
